import { NgModule } from '@angular/core';
import { LTButtonComponent } from 'src/app/UI/components/lt-button/lt-button.component';
import { LTTableColumnDirective } from 'src/app/UI/components/lt-table/lt-table-column.directive';
import { LTTableComponent } from 'src/app/UI/components/lt-table/lt-table.component';

@NgModule({
  imports: [LTButtonComponent, LTTableComponent, LTTableColumnDirective],
  exports: [LTButtonComponent, LTTableComponent, LTTableColumnDirective],
})
export class UIModule {}
