import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[lt-table-column]',
  standalone: true,
})
export class LTTableColumnDirective<T> {
  private _alignCenter = false;
  private _collapsed = false;
  private _sortable = false;

  @Input('lt-table-column') field: keyof T; // Поле данных
  @Input() header: string; // Заголовок колонки
  @ContentChild('cellTemplate', { read: TemplateRef }) cellTemplate: TemplateRef<Element>; // Шаблон ячейки

  // Возможность сортировки
  @Input()
  get sortable(): boolean {
    return this._sortable;
  }

  set sortable(value: BooleanInput) {
    this._sortable = coerceBooleanProperty(value);
  }

  // Выравнивание колонки по центру (дефолтное значение по левому краю)
  @Input()
  get alignCenter(): boolean {
    return this._alignCenter;
  }

  set alignCenter(value: BooleanInput) {
    this._alignCenter = coerceBooleanProperty(value);
  }

  // Уменьшенная ширина колонки (64px) для кнопок-иконок
  @Input()
  get collapsed(): boolean {
    return this._collapsed;
  }

  set collapsed(value: BooleanInput) {
    this._collapsed = coerceBooleanProperty(value);
  }
}
