<div class="table-container">
  <table class="table">
    <thead class="table-head">
      <tr>
        <th
          *ngFor="let column of columns"
          class="table-head__cell"
          [ngClass]="{
            'table-head__cell--center': column.alignCenter,
            'table-head__cell--collapsed': column.collapsed
          }"
        >
          {{ column.header }}

          <span
            *ngIf="column.sortable"
            class="sort-indicator"
            [ngClass]="{
              'sort-indicator--active': currentSort.key === column.field,
              'sort-indicator--hover': currentSort.key !== column.field
            }"
            (click)="onSort(column)"
          >
            {{ currentSort.ascending ? '↑' : '↓' }}
          </span>
        </th>
      </tr>
    </thead>

    <tbody class="table-body scrollbar scrollbar--wide">
      <tr *ngFor="let row of dataSource" class="table-body__row">
        <td
          *ngFor="let column of columns"
          class="table-body__cell"
          [ngClass]="{ 'table-body__cell--center': column.alignCenter, 'table-body__cell--collapsed': column.collapsed }"
        >
          <ng-container *ngTemplateOutlet="column.cellTemplate; context: { $implicit: row }"></ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
